<template>
  <div>
    <div class="pb-3">
      <b-button @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') }}</span>
      </b-button>

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') }}</span>
      </b-button>
      <!-- clear button -->

      <download-excel class="ml-1 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.sessions"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') }}
      </download-excel>
    </div>


    <!-- List page filter -->
    <application-log-list-filter class="d-inline-block w-75 mb-1" @search="setFilter"
                                 v-model="filter"></application-log-list-filter>
    <!-- List page filter -->

    <!-- Page size -->
    <div class="d-inline-block w-25">
      <page-size v-model="size" class="mb-1"></page-size>
    </div>
    <!-- Page size -->

    <b-table
        id="sessions-table"
        ref="sessionsTable"
        v-model="items"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        :current-page="page"
        :filter="filter"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('LOG')}) }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <template #head(date)="">
        <span>{{ labels.date }}</span>
      </template>

      <template #cell(date)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.date | date('DD/MM/YYYY HH:mm:ss') }}</span>
        </div>
      </template>

      <template #head(catalog)="">
        <span>{{ labels.catalog }}</span>
      </template>

      <template #cell(catalog)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(data.item.catalog) }}</span>
        </div>
      </template>

      <template #head(operation)="">
        <span>{{ labels.operation }}</span>
      </template>

      <template #cell(operation)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t('LOG_' + data.item.operation + '_LABEL') }}</span>
        </div>
      </template>

      <template #head(data)="">
        <span>{{ labels.data }}</span>
      </template>

      <template #cell(data)="data">
        <div>
          {{ data.item.data }}
        </div>
      </template>

      <template #head(username)="">
        <span>{{ labels.username }}</span>
      </template>

      <template #cell(username)="data">
        <div>{{ data.item.username }}</div>
      </template>
    </b-table>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="sessions-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL') }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Table} from "@/mixins";
import eventBus from "@/events";
import PageSize from "@/components/PageSize";
import ApplicationLogListFilter from "@/log/ApplicationLogListFilter";
import Log from "@/log/index";

export default {
  name: "SessionList",
  components: {PageSize, ApplicationLogListFilter},
  props: ['selectMode', 'currentlySelected'],
  mixins: [Table, DateFilter],
  mounted() {
    eventBus.$on('EVENT_ON_LOG_LIST', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {},
  data() {
    return {
      labels: {
        date: this.$t('LOG_DATE_LABEL'),
        catalog: this.$t('LOG_CATALOG_LABEL'),
        operation: this.$t('LOG_OPERATION_LABEL'),
        data: this.$t('LOG_DATA_LABEL'),
        username: this.$t('LOG_USERNAME_LABEL'),
        excel: this.$t('LOG') + '.xls',
      },
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'date', sortable: true, tdClass: 'customTd'},
        {key: 'username', sortable: true, tdClass: 'customTd'},
        {key: 'catalog', sortable: true, tdClass: 'customTd'},
        {key: 'operation', sortable: true, tdClass: 'customTd'},
        {key: 'data', sortable: true, tdClass: 'customTd'},
      ];
    },
    excelFields() {
      const fields = {};

      fields[this.labels.date] = 'date';
      fields[this.labels.catalog] = 'catalog';
      fields[this.labels.operation] = 'operation';
      fields[this.labels.data] = 'data';
      fields[this.labels.username] = 'username';
      fields[this.labels.validThru] = 'validThru';

      return fields;
    },
    tableRef() {
      return this.$refs.sessionsTable;
    },
    controller() {
      return Log;
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}
</style>
