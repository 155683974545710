<template>
  <div>
    <b-form inline>
      <div class="w-25">
        <b-form-datepicker
            @input="search"
            v-model="filter.date"
            right
            locale="es-MX"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            button-variant="dark"
            aria-controls="example-input"
            :label-no-date-selected="$t('LOG_DATE_LABEL')"></b-form-datepicker>
      </div>

      <div class="w-25 pl-1">
        <b-select class="w-100" @change="search" v-model="filter.operation">
          <b-select-option :value="undefined">{{ $t('LOG_ALL_OPERATIONS_LABEL') }}</b-select-option>
          <b-select-option value="CREATED">{{ $t('LOG_CREATED_LABEL') }}</b-select-option>
          <b-select-option value="UPDATED">{{ $t('LOG_UPDATED_LABEL') }}</b-select-option>
          <b-select-option value="ENABLED">{{ $t('LOG_ENABLED_LABEL') }}</b-select-option>
          <b-select-option value="DISABLED">{{ $t('LOG_DISABLED_LABEL') }}</b-select-option>
          <b-select-option value="PASSWORD_CHANGE">{{ $t('LOG_PASSWORD_CHANGE_LABEL') }}</b-select-option>
          <b-select-option value="DELETED">{{ $t('LOG_DELETED_LABEL') }}</b-select-option>
          <b-select-option value="CHANGE_BUS_STATUS">{{ $t('LOG_CHANGE_BUS_STATUS_LABEL') }}</b-select-option>
          <b-select-option value="CHANGE_DRIVER_STATUS">{{ $t('LOG_CHANGE_DRIVER_STATUS_LABEL') }}</b-select-option>
          <b-select-option value="CHANGE_PASS_STATUS">{{ $t('LOG_CHANGE_PASS_STATUS_LABEL') }}</b-select-option>
          <b-select-option value="CHANGED_TO_NEXT_STATUS">{{ $t('LOG_CHANGED_TO_NEXT_STATUS_LABEL') }}</b-select-option>
        </b-select>
      </div>

      <div class="w-25 pl-1">
        <b-select class="w-100" @change="search" v-model="filter.catalog">
          <b-select-option :value="undefined">{{ $t('LOG_ALL_CATALOGS') }}</b-select-option>
          <b-select-option value="USER">{{ $t('USERS') }}</b-select-option>
          <b-select-option value="PROFILE">{{ $t('PROFILES') }}</b-select-option>
          <b-select-option value="TRAVEL_TYPE">{{ $t('TRAVEL_TYPES') }}</b-select-option>
          <b-select-option value="TURN">{{ $t('TURNS') }}</b-select-option>
          <b-select-option value="ROUTE">{{ $t('ROUTES') }}</b-select-option>
          <b-select-option value="DRIVER">{{ $t('DRIVERS') }}</b-select-option>
          <b-select-option value="BUS">{{ $t('BUSES') }}</b-select-option>
          <b-select-option value="COMPANY">{{ $t('COMPANIES') }}</b-select-option>
          <b-select-option value="DIESEL_CONTROL">{{ $t('DIESEL_CONTROLS') }}</b-select-option>
          <b-select-option value="TRAVEL">{{ $t('TRAVELS') }}</b-select-option>
          <b-select-option value="PASS">{{ $t('PASSES') }}</b-select-option>
        </b-select>
      </div>
    </b-form>
  </div>
</template>

<script>
import {Filter, VModel} from "@/mixins";

export default {
  name: "SessionListFilter",
  mixins: [Filter, VModel],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      this.$emit('search', this.filter);
    },
    resetFilter() {
      this.filter = {};
    }
  }
}
</script>

<style scoped>

</style>
