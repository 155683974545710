import http from "@/http";
import eventBus from "@/events";

const Log = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'date') + sortDesc;

        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort};
        const params = {...filter, ...pagination};
        const resp = await http("/applicationLogs/search/all", {params});
        eventBus.$emit('EVENT_ON_LOG_LIST', resp.data.page.totalElements);
        return resp.data['_embedded']['applicationLogs'];
    },
}

export default Log;
